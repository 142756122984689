"use client";
import { convertCartToApiCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";

import { LocalStagePath, NuxtStoreCart } from "./types";
import { setLocalStorageValue } from "./useClientLocalStorage/helpers";

const storeKey: LocalStagePath = `vuex.cart.cart`;

/**
 * Nextから`vuex.cart.cart`のストレージを参照することはない。
 * Nuxt側で参照することがあるため、`vuex.cart.cart`のストレージを更新するための関数。
 * そのためhooksではなく、関数として定義している。
 * @param cart
 */
export const setClientCartCart = (cart: CartModel | undefined | null) => {
  const storedCart = cart ? convertCartToApiCart(cart) : {};
  setLocalStorageValue<NuxtStoreCart["cart"]>(storeKey, storedCart);
};
