/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import type {
  AcceptLatestAgreement200,
  AmazonGetCheckout200,
  AmazonGetCheckoutBodyOne,
  AmazonGetCheckoutBodyTwo,
  AmazonGetCheckoutUrl200,
  AmazonGetCheckoutUrlBody,
  AmazonGetPermissionUrl200,
  AmazonGetPermissionUrlBodyOne,
  AmazonGetPermissionUrlBodyTwo,
  AmazonPayload200,
  AmazonPayloadParams,
  AmazonPayloadUpdate200,
  AmazonSavePermissionUrl200,
  AmazonSavePermissionUrlBodyOne,
  AmazonSavePermissionUrlBodyTwo,
  CalcSubscriptionCartBody,
  Cart,
  CartCalcApiResponse,
  CartValidateResponse,
  ChangeCard200,
  ChangeCardBodyOne,
  ChangeCardBodyTwo,
  CheckDuplicateCard200,
  CheckDuplicateCardBodyOne,
  CheckDuplicateCardBodyTwo,
  CheckHoldBackCoupon200,
  CheckHoldBackCouponParams,
  CheckSubscriptionDetailLineAt200,
  CheckSubscriptionDetailLineAtParams,
  CreateOrderPostBody,
  CreateOrderResponse,
  CustomerResponse,
  DeleteSubscriptionParams,
  GetCardLast4200,
  GetCartParams,
  GetCartValidDates200,
  GetCheckoutProgress200,
  GetCommuneSignInUrl200,
  GetCustomerIsRegistered200One,
  GetCustomerIsRegistered200Two,
  GetCustomerIsRegisteredParams,
  GetCustomerMiles200,
  GetCustomerMilesParams,
  GetCustomerOrderAndDeliveryStatus200,
  GetCustomerOrderAndDeliveryStatusParams,
  GetCustomerOrderDetail200,
  GetCustomerOrderDetailParams,
  GetCustomerOrders200,
  GetCustomerOrdersParams,
  GetCustomerOrdersStampsProducts200,
  GetCustomerOrdersSummery200,
  GetCustomerPointDetail200,
  GetCustomerPointDetailParams,
  GetCustomerPoints200,
  GetCustomerPointsParams,
  GetInviteCode200,
  GetLineAtCheckRelated200,
  GetLineAtCheckRelatedParams,
  GetMilesGiftUrlParams,
  GetMyPageSummary200,
  GetMypageLink200,
  GetPointMile200,
  GetPointProduct200,
  GetPointProductParams,
  GetPoints200,
  GetProductsByNamesParams,
  GetProductsSkusParams,
  GetSurveyResponse,
  GetValidDates200,
  InviteCodeLineAt200,
  InviteCodeLineAtParams,
  Login200,
  LoginBodyOne,
  LoginBodyTwo,
  LoginCheckout200,
  LoginCheckoutBodyOne,
  LoginCheckoutBodyTwo,
  LoginCommune200,
  LoginCommuneBodyOne,
  LoginCommuneBodyTwo,
  LoginLineAt200,
  LoginLineAtBodyOne,
  LoginLineAtBodyTwo,
  LoginLog200,
  MileResponse,
  Mypage200,
  MypageBodyOne,
  MypageBodyTwo,
  PostCartBody,
  PostPointProductConfirm200,
  PostPointProductConfirmBody,
  PostPointProductCreate200,
  PostPointProductCreateBody,
  Product,
  QuitLineAt200,
  QuitLineAtParams,
  RegisterLineAt200,
  RegisterLineAtParams,
  ResetPassword200,
  ResetPasswordBodyOne,
  ResetPasswordBodyTwo,
  RoyaltyRank,
  SaveCheckoutProgress200,
  SaveCheckoutProgressBody,
  SaveSurvey200,
  SaveSurveyBody,
  SendPasswordResetMailBodyOne,
  SendPasswordResetMailBodyTwo,
  SignupEmail200,
  SignupEmailBodyOne,
  SignupEmailBodyTwo,
  SignupPassword200,
  SignupPasswordBodyOne,
  SignupPasswordBodyTwo,
  SubmitCard200,
  SubmitCardBodyOne,
  SubmitCardBodyTwo,
  Subscription,
  SubscriptionResponse,
  UpdateCustomerEmail200,
  UpdateCustomerEmailBody,
  UpdateCustomerShippingAddress200,
  UpdateCustomerShippingAddressBody,
  ValidateCartBody,
  ValidateCoupon200,
  ValidateCouponBody,
  WelcomeSlideStatus200,
} from "../open-api/schemas";
import { customAxiosInstance } from "../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get cancellation survey data
 */
export const getCancellationSurvey = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/cancellation`, method: "GET" },
    options
  );
};

/**
 * @summary get normal skip survey
 */
export const getNormalSkipSurvey = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/normal_skip`, method: "GET" },
    options
  );
};

/**
 * @summary get freeze skip survey
 */
export const getFreezeSkipSurvey = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/freeze_skip`, method: "GET" },
    options
  );
};

/**
 * @summary get after purchase survey data
 */
export const getAfterPurchaseSurvey = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetSurveyResponse>(
    { url: `/survey/after_purchase`, method: "GET" },
    options
  );
};

/**
 * @summary save survey data
 */
export const saveSurvey = (
  saveSurveyBody: SaveSurveyBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(saveSurveyBody);
  return customAxiosInstance<SaveSurvey200>(
    {
      url: `/survey/save`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary check if login or not
 */
export const getCustomer = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<CustomerResponse>({ url: `/customer`, method: "GET" }, options);
};

/**
 * @summary delete customer
 */
export const deleteCustomer = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<void>({ url: `/customer`, method: "DELETE" }, options);
};

/**
 * @summary check customer registered
 */
export const getCustomerIsRegistered = (
  params: GetCustomerIsRegisteredParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerIsRegistered200One | GetCustomerIsRegistered200Two>(
    { url: `/customer/is_registered`, method: "POST", params },
    options
  );
};

/**
 * @summary send password reset mail
 */
export const sendPasswordResetMail = (
  sendPasswordResetMailBody: SendPasswordResetMailBodyOne | SendPasswordResetMailBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/customer/send_password_reset_mail`, method: "POST", data: sendPasswordResetMailBody },
    options
  );
};

/**
 * @summary signup email
 */
export const signupEmail = (
  signupEmailBody: SignupEmailBodyOne | SignupEmailBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<SignupEmail200>(
    { url: `/customer/signup`, method: "POST", data: signupEmailBody },
    options
  );
};

/**
 * @summary signup password
 */
export const signupPassword = (
  signupPasswordBody: SignupPasswordBodyOne | SignupPasswordBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<SignupPassword200>(
    { url: `/customer/signup/password`, method: "POST", data: signupPasswordBody },
    options
  );
};

/**
 * @summary reset password
 */
export const resetPassword = (
  resetPasswordBody: ResetPasswordBodyOne | ResetPasswordBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<ResetPassword200>(
    { url: `/customer/reset_password`, method: "POST", data: resetPasswordBody },
    options
  );
};

/**
 * @summary update customer email
 */
export const updateCustomerEmail = (
  updateCustomerEmailBody: UpdateCustomerEmailBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<UpdateCustomerEmail200>(
    {
      url: `/customer/email`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCustomerEmailBody,
    },
    options
  );
};

/**
 * @summary update customer shipping_address
 */
export const updateCustomerShippingAddress = (
  updateCustomerShippingAddressBody: UpdateCustomerShippingAddressBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<UpdateCustomerShippingAddress200>(
    {
      url: `/customer/shipping_address`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCustomerShippingAddressBody,
    },
    options
  );
};

/**
 * @summary get customer orders summary
 */
export const getCustomerOrdersSummery = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetCustomerOrdersSummery200>(
    { url: `/customer/orders/summary`, method: "GET" },
    options
  );
};

/**
 * @summary get customer miles
 */
export const getCustomerMiles = (
  params: GetCustomerMilesParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerMiles200>(
    { url: `/customer/miles`, method: "GET", params },
    options
  );
};

/**
 * @summary get customer stamp product
 */
export const getCustomerOrdersStampsProducts = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerOrdersStampsProducts200>(
    { url: `/customer/orders/stamps/products`, method: "GET" },
    options
  );
};

/**
 * @summary customer login
 */
export const mypage = (
  mypageBody: MypageBodyOne | MypageBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Mypage200>(
    { url: `/mypage`, method: "POST", data: mypageBody },
    options
  );
};

/**
 * @summary get mypage summary
 */
export const getMyPageSummary = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetMyPageSummary200>(
    { url: `/mypage/summary`, method: "GET" },
    options
  );
};

export const getMyPageSummaryImageName = (
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<string>({ url: `/mypage/summary_image_name`, method: "GET" }, options);
};

/**
 * @summary customer login checkout
 */
export const loginCheckout = (
  loginCheckoutBody: LoginCheckoutBodyOne | LoginCheckoutBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<LoginCheckout200>(
    { url: `/loginCheckout`, method: "POST", data: loginCheckoutBody },
    options
  );
};

/**
 * @summary customer get commune login url
 */
export const getCommuneSignInUrl = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetCommuneSignInUrl200>(
    { url: `/commune/signin/url`, method: "GET" },
    options
  );
};

/**
 * @summary customer login commune
 */
export const loginCommune = (
  loginCommuneBody: LoginCommuneBodyOne | LoginCommuneBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<LoginCommune200>(
    { url: `/loginCommune`, method: "POST", data: loginCommuneBody },
    options
  );
};

/**
 * @summary customer login
 */
export const login = (
  loginBody: LoginBodyOne | LoginBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Login200>({ url: `/login`, method: "POST", data: loginBody }, options);
};

/**
 * @summary customer login lineAt
 */
export const loginLineAt = (
  loginLineAtBody: LoginLineAtBodyOne | LoginLineAtBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<LoginLineAt200>(
    { url: `/loginLineAt`, method: "POST", data: loginLineAtBody },
    options
  );
};

/**
 * @summary customer logout
 */
export const logout = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<void>({ url: `/logout`, method: "POST" }, options);
};

/**
 * @summary calc cart product price
 */
export const postCart = (
  postCartBody: PostCartBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(postCartBody);
  return customAxiosInstance<CartCalcApiResponse>(
    {
      url: `/cart`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary calc cart product price in lp
 */
export const getCart = (
  params: GetCartParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CartCalcApiResponse>({ url: `/cart`, method: "GET", params }, options);
};

/**
 * @summary validate user input as cart
 */
export const validateCart = (
  validateCartBody: ValidateCartBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(validateCartBody);
  return customAxiosInstance<CartValidateResponse>(
    {
      url: `/cart/validate`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary save checkout prgress for email marketing
 */
export const saveCheckoutProgress = (
  saveCheckoutProgressBody: SaveCheckoutProgressBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(saveCheckoutProgressBody);
  return customAxiosInstance<SaveCheckoutProgress200>(
    {
      url: `/cart/in_progress`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary get checkout prgress
 */
export const getCheckoutProgress = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetCheckoutProgress200>(
    { url: `/cart/get_progress`, method: "POST" },
    options
  );
};

/**
 * @summary get cart valid dates
 */
export const getCartValidDates = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetCartValidDates200>(
    { url: `/cart/valid_dates`, method: "GET" },
    options
  );
};

/**
 * @summary create order
 */
export const createOrderPost = (
  createOrderPostBody: CreateOrderPostBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(createOrderPostBody);
  return customAxiosInstance<CreateOrderResponse>(
    {
      url: `/order`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary Amazon Pay button display
 */
export const amazonPayload = (
  params: AmazonPayloadParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonPayload200>(
    { url: `/amazon/payload`, method: "GET", params },
    options
  );
};

/**
 * @summary address of amazon authenticated user
 */
export const amazonGetCheckout = (
  amazonGetCheckoutBody: AmazonGetCheckoutBodyOne | AmazonGetCheckoutBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonGetCheckout200>(
    { url: `/amazon/get_checkout`, method: "POST", data: amazonGetCheckoutBody },
    options
  );
};

/**
 * @summary get amazon pay checkout url
 */
export const amazonGetCheckoutUrl = (
  amazonGetCheckoutUrlBody: AmazonGetCheckoutUrlBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonGetCheckoutUrl200>(
    {
      url: `/amazon/get_checkout_url`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: amazonGetCheckoutUrlBody,
    },
    options
  );
};

/**
 * @summary get Payload And Signature Update Subscription
 */
export const amazonPayloadUpdate = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<AmazonPayloadUpdate200>(
    { url: `/amazon/payload_update`, method: "GET" },
    options
  );
};

/**
 * @summary amazon pay get permission url
 */
export const amazonGetPermissionUrl = (
  amazonGetPermissionUrlBody: AmazonGetPermissionUrlBodyOne | AmazonGetPermissionUrlBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonGetPermissionUrl200>(
    { url: `/amazon/get_permission_url`, method: "POST", data: amazonGetPermissionUrlBody },
    options
  );
};

/**
 * @summary amazon pay save permission
 */
export const amazonSavePermissionUrl = (
  amazonSavePermissionUrlBody: AmazonSavePermissionUrlBodyOne | AmazonSavePermissionUrlBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<AmazonSavePermissionUrl200>(
    { url: `/amazon/save_permission`, method: "POST", data: amazonSavePermissionUrlBody },
    options
  );
};

/**
 * @summary get check related result
 */
export const getLineAtCheckRelated = (
  params: GetLineAtCheckRelatedParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetLineAtCheckRelated200>(
    { url: `/lineAt/checkRelated`, method: "GET", params },
    options
  );
};

/**
 * @summary register line_user_id to payse
 */
export const registerLineAt = (
  params: RegisterLineAtParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<RegisterLineAt200>(
    { url: `/lineAt/register`, method: "POST", params },
    options
  );
};

/**
 * @summary Unlink from lineAt
 */
export const quitLineAt = (
  params: QuitLineAtParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<QuitLineAt200>(
    { url: `/lineAt/quit`, method: "POST", params },
    options
  );
};

/**
 * @summary check Subscription Detail for LineAt User
 */
export const checkSubscriptionDetailLineAt = (
  params: CheckSubscriptionDetailLineAtParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CheckSubscriptionDetailLineAt200>(
    { url: `/lineAt/checkSubscriptionDetail`, method: "POST", params },
    options
  );
};

/**
 * @summary get Invite Code
 */
export const inviteCodeLineAt = (
  params: InviteCodeLineAtParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<InviteCodeLineAt200>(
    { url: `/lineAt/inviteCode`, method: "POST", params },
    options
  );
};

/**
 * @summary get product information by product name
 */
export const getProductsName = (
  name: string,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Product>({ url: `/products/name/${name}`, method: "GET" }, options);
};

/**
 * @summary get product information by product names
 */
export const getProductsByNames = (
  params: GetProductsByNamesParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Product[]>({ url: `/products/names`, method: "GET", params }, options);
};

/**
 * @summary get product information by product skus
 */
export const getProductsSkus = (
  params: GetProductsSkusParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Product[]>({ url: `/products/skus`, method: "GET", params }, options);
};

/**
 * @summary get subscription data
 */
export const getSubscription = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<SubscriptionResponse>(
    { url: `/subscription`, method: "GET" },
    options
  );
};

/**
 * @summary update subscription
 */
export const updateSubscription = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/subscription`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

/**
 * @summary delete subscription
 */
export const deleteSubscription = (
  params: DeleteSubscriptionParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>({ url: `/subscription`, method: "DELETE", params }, options);
};

/**
 * @summary validate coupon code
 */
export const validateCoupon = (
  validateCouponBody: ValidateCouponBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(validateCouponBody);
  return customAxiosInstance<ValidateCoupon200>(
    {
      url: `/subscription/coupon/validate`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary update subscription
 */
export const updateSubscriptionBillingInfo = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/subscription/billing_info`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

/**
 * @summary update subscription coupon
 */
export const updateSubscriptionCoupon = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Cart>(
    {
      url: `/subscription/coupon`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

/**
 * @summary update subscription delivery_date
 */
export const updateSubscriptionDeliveryDate = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/subscription/delivery_date`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

/**
 * @summary update subscription point
 */
export const updateSubscriptionPoint = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Cart>(
    {
      url: `/subscription/point`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

/**
 * @summary get card_last4 data
 */
export const getCardLast4 = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetCardLast4200>({ url: `/card_last4`, method: "GET" }, options);
};

/**
 * @summary calc subscription cart
 */
export const calcSubscriptionCart = (
  calcSubscriptionCartBody: CalcSubscriptionCartBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(calcSubscriptionCartBody);
  return customAxiosInstance<CartCalcApiResponse>(
    {
      url: `/subscription/cart`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

/**
 * @summary get valid dates
 */
export const getValidDates = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetValidDates200>({ url: `/valid_dates`, method: "GET" }, options);
};

/**
 * @summary get points
 */
export const getPoints = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetPoints200>({ url: `/points`, method: "GET" }, options);
};

/**
 * @summary get miles
 */
export const getMiles = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<MileResponse>({ url: `/miles`, method: "GET" }, options);
};

/**
 * @summary get mypage link
 */
export const getMypageLink = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetMypageLink200>(
    { url: `/miles/mypageLink`, method: "GET" },
    options
  );
};

/**
 * @summary get mile gift link
 */
export const getMilesGiftUrl = (
  params: GetMilesGiftUrlParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<RoyaltyRank>(
    { url: `/miles/gift/url`, method: "GET", params },
    options
  );
};

/**
 * @summary get point mile
 */
export const getPointMile = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetPointMile200>({ url: `/point_mile`, method: "GET" }, options);
};

/**
 * @summary get invite code
 */
export const getInviteCode = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<GetInviteCode200>({ url: `/invites/code`, method: "GET" }, options);
};

/**
 * @summary get customer orders
 */
export const getCustomerOrders = (
  params: GetCustomerOrdersParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerOrders200>(
    { url: `/customer/orders`, method: "GET", params },
    options
  );
};

/**
 * @summary get customer order detail
 */
export const getCustomerOrderDetail = (
  params: GetCustomerOrderDetailParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerOrderDetail200>(
    { url: `/customer/order_detail`, method: "GET", params },
    options
  );
};

/**
 * @summary get customer order and delivery status
 */
export const getCustomerOrderAndDeliveryStatus = (
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerOrderAndDeliveryStatus200>(
    { url: `/customer/order_and_delivery_status`, method: "GET", params },
    options
  );
};

/**
 * @summary check hold back coupon
 */
export const checkHoldBackCoupon = (
  params: CheckHoldBackCouponParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CheckHoldBackCoupon200>(
    { url: `/subscription/check_hold_back_coupon`, method: "GET", params },
    options
  );
};

/**
 * @summary submit card
 */
export const submitCard = (
  submitCardBody: SubmitCardBodyOne | SubmitCardBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<SubmitCard200>(
    { url: `/subscription/submit_card`, method: "PUT", data: submitCardBody },
    options
  );
};

/**
 * @summary change card
 */
export const changeCard = (
  changeCardBody: ChangeCardBodyOne | ChangeCardBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<ChangeCard200>(
    { url: `/subscription/change_card`, method: "PUT", data: changeCardBody },
    options
  );
};

/**
 * @summary check duplicate card
 */
export const checkDuplicateCard = (
  checkDuplicateCardBody: CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CheckDuplicateCard200>(
    { url: `/subscription/check_duplicate_card`, method: "POST", data: checkDuplicateCardBody },
    options
  );
};

/**
 * @summary post login log
 */
export const loginLog = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<LoginLog200>({ url: `/post_login_log`, method: "POST" }, options);
};

/**
 * @summary accept Latest Agreement
 */
export const acceptLatestAgreement = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<AcceptLatestAgreement200>(
    { url: `/acceptLatestAgreement`, method: "POST" },
    options
  );
};

/**
 * @summary get customer points
 */
export const getCustomerPoints = (
  params: GetCustomerPointsParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerPoints200>(
    { url: `/customer/points`, method: "GET", params },
    options
  );
};

/**
 * @summary get customer order point
 */
export const getCustomerPointDetail = (
  params: GetCustomerPointDetailParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerPointDetail200>(
    { url: `/customer/point_detail`, method: "GET", params },
    options
  );
};

/**
 * @summary get welcome status
 */
export const welcomeSlideStatus = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<WelcomeSlideStatus200>(
    { url: `/welcome/slide/status`, method: "GET" },
    options
  );
};

/**
 * @summary get point product
 */
export const getPointProduct = (
  params: GetPointProductParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetPointProduct200>(
    { url: `/pointCart/fetchProduct`, method: "GET", params },
    options
  );
};

/**
 * @summary post point product confirm
 */
export const postPointProductConfirm = (
  postPointProductConfirmBody: PostPointProductConfirmBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<PostPointProductConfirm200>(
    {
      url: `/pointCart/confirm`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPointProductConfirmBody,
    },
    options
  );
};

/**
 * @summary purchase point product create
 */
export const postPointProductCreate = (
  postPointProductCreateBody: PostPointProductCreateBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<PostPointProductCreate200>(
    {
      url: `/pointCart/create`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPointProductCreateBody,
    },
    options
  );
};

export type GetCancellationSurveyResult = NonNullable<
  Awaited<ReturnType<typeof getCancellationSurvey>>
>;
export type GetNormalSkipSurveyResult = NonNullable<
  Awaited<ReturnType<typeof getNormalSkipSurvey>>
>;
export type GetFreezeSkipSurveyResult = NonNullable<
  Awaited<ReturnType<typeof getFreezeSkipSurvey>>
>;
export type GetAfterPurchaseSurveyResult = NonNullable<
  Awaited<ReturnType<typeof getAfterPurchaseSurvey>>
>;
export type SaveSurveyResult = NonNullable<Awaited<ReturnType<typeof saveSurvey>>>;
export type GetCustomerResult = NonNullable<Awaited<ReturnType<typeof getCustomer>>>;
export type DeleteCustomerResult = NonNullable<Awaited<ReturnType<typeof deleteCustomer>>>;
export type GetCustomerIsRegisteredResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerIsRegistered>>
>;
export type SendPasswordResetMailResult = NonNullable<
  Awaited<ReturnType<typeof sendPasswordResetMail>>
>;
export type SignupEmailResult = NonNullable<Awaited<ReturnType<typeof signupEmail>>>;
export type SignupPasswordResult = NonNullable<Awaited<ReturnType<typeof signupPassword>>>;
export type ResetPasswordResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>;
export type UpdateCustomerEmailResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomerEmail>>
>;
export type UpdateCustomerShippingAddressResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomerShippingAddress>>
>;
export type GetCustomerOrdersSummeryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrdersSummery>>
>;
export type GetCustomerMilesResult = NonNullable<Awaited<ReturnType<typeof getCustomerMiles>>>;
export type GetCustomerOrdersStampsProductsResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>
>;
export type MypageResult = NonNullable<Awaited<ReturnType<typeof mypage>>>;
export type GetMyPageSummaryResult = NonNullable<Awaited<ReturnType<typeof getMyPageSummary>>>;
export type GetMyPageSummaryImageNameResult = NonNullable<
  Awaited<ReturnType<typeof getMyPageSummaryImageName>>
>;
export type LoginCheckoutResult = NonNullable<Awaited<ReturnType<typeof loginCheckout>>>;
export type GetCommuneSignInUrlResult = NonNullable<
  Awaited<ReturnType<typeof getCommuneSignInUrl>>
>;
export type LoginCommuneResult = NonNullable<Awaited<ReturnType<typeof loginCommune>>>;
export type LoginResult = NonNullable<Awaited<ReturnType<typeof login>>>;
export type LoginLineAtResult = NonNullable<Awaited<ReturnType<typeof loginLineAt>>>;
export type LogoutResult = NonNullable<Awaited<ReturnType<typeof logout>>>;
export type PostCartResult = NonNullable<Awaited<ReturnType<typeof postCart>>>;
export type GetCartResult = NonNullable<Awaited<ReturnType<typeof getCart>>>;
export type ValidateCartResult = NonNullable<Awaited<ReturnType<typeof validateCart>>>;
export type SaveCheckoutProgressResult = NonNullable<
  Awaited<ReturnType<typeof saveCheckoutProgress>>
>;
export type GetCheckoutProgressResult = NonNullable<
  Awaited<ReturnType<typeof getCheckoutProgress>>
>;
export type GetCartValidDatesResult = NonNullable<Awaited<ReturnType<typeof getCartValidDates>>>;
export type CreateOrderPostResult = NonNullable<Awaited<ReturnType<typeof createOrderPost>>>;
export type AmazonPayloadResult = NonNullable<Awaited<ReturnType<typeof amazonPayload>>>;
export type AmazonGetCheckoutResult = NonNullable<Awaited<ReturnType<typeof amazonGetCheckout>>>;
export type AmazonGetCheckoutUrlResult = NonNullable<
  Awaited<ReturnType<typeof amazonGetCheckoutUrl>>
>;
export type AmazonPayloadUpdateResult = NonNullable<
  Awaited<ReturnType<typeof amazonPayloadUpdate>>
>;
export type AmazonGetPermissionUrlResult = NonNullable<
  Awaited<ReturnType<typeof amazonGetPermissionUrl>>
>;
export type AmazonSavePermissionUrlResult = NonNullable<
  Awaited<ReturnType<typeof amazonSavePermissionUrl>>
>;
export type GetLineAtCheckRelatedResult = NonNullable<
  Awaited<ReturnType<typeof getLineAtCheckRelated>>
>;
export type RegisterLineAtResult = NonNullable<Awaited<ReturnType<typeof registerLineAt>>>;
export type QuitLineAtResult = NonNullable<Awaited<ReturnType<typeof quitLineAt>>>;
export type CheckSubscriptionDetailLineAtResult = NonNullable<
  Awaited<ReturnType<typeof checkSubscriptionDetailLineAt>>
>;
export type InviteCodeLineAtResult = NonNullable<Awaited<ReturnType<typeof inviteCodeLineAt>>>;
export type GetProductsNameResult = NonNullable<Awaited<ReturnType<typeof getProductsName>>>;
export type GetProductsByNamesResult = NonNullable<Awaited<ReturnType<typeof getProductsByNames>>>;
export type GetProductsSkusResult = NonNullable<Awaited<ReturnType<typeof getProductsSkus>>>;
export type GetSubscriptionResult = NonNullable<Awaited<ReturnType<typeof getSubscription>>>;
export type UpdateSubscriptionResult = NonNullable<Awaited<ReturnType<typeof updateSubscription>>>;
export type DeleteSubscriptionResult = NonNullable<Awaited<ReturnType<typeof deleteSubscription>>>;
export type ValidateCouponResult = NonNullable<Awaited<ReturnType<typeof validateCoupon>>>;
export type UpdateSubscriptionBillingInfoResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionBillingInfo>>
>;
export type UpdateSubscriptionCouponResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionCoupon>>
>;
export type UpdateSubscriptionDeliveryDateResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionDeliveryDate>>
>;
export type UpdateSubscriptionPointResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionPoint>>
>;
export type GetCardLast4Result = NonNullable<Awaited<ReturnType<typeof getCardLast4>>>;
export type CalcSubscriptionCartResult = NonNullable<
  Awaited<ReturnType<typeof calcSubscriptionCart>>
>;
export type GetValidDatesResult = NonNullable<Awaited<ReturnType<typeof getValidDates>>>;
export type GetPointsResult = NonNullable<Awaited<ReturnType<typeof getPoints>>>;
export type GetMilesResult = NonNullable<Awaited<ReturnType<typeof getMiles>>>;
export type GetMypageLinkResult = NonNullable<Awaited<ReturnType<typeof getMypageLink>>>;
export type GetMilesGiftUrlResult = NonNullable<Awaited<ReturnType<typeof getMilesGiftUrl>>>;
export type GetPointMileResult = NonNullable<Awaited<ReturnType<typeof getPointMile>>>;
export type GetInviteCodeResult = NonNullable<Awaited<ReturnType<typeof getInviteCode>>>;
export type GetCustomerOrdersResult = NonNullable<Awaited<ReturnType<typeof getCustomerOrders>>>;
export type GetCustomerOrderDetailResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrderDetail>>
>;
export type GetCustomerOrderAndDeliveryStatusResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>
>;
export type CheckHoldBackCouponResult = NonNullable<
  Awaited<ReturnType<typeof checkHoldBackCoupon>>
>;
export type SubmitCardResult = NonNullable<Awaited<ReturnType<typeof submitCard>>>;
export type ChangeCardResult = NonNullable<Awaited<ReturnType<typeof changeCard>>>;
export type CheckDuplicateCardResult = NonNullable<Awaited<ReturnType<typeof checkDuplicateCard>>>;
export type LoginLogResult = NonNullable<Awaited<ReturnType<typeof loginLog>>>;
export type AcceptLatestAgreementResult = NonNullable<
  Awaited<ReturnType<typeof acceptLatestAgreement>>
>;
export type GetCustomerPointsResult = NonNullable<Awaited<ReturnType<typeof getCustomerPoints>>>;
export type GetCustomerPointDetailResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPointDetail>>
>;
export type WelcomeSlideStatusResult = NonNullable<Awaited<ReturnType<typeof welcomeSlideStatus>>>;
export type GetPointProductResult = NonNullable<Awaited<ReturnType<typeof getPointProduct>>>;
export type PostPointProductConfirmResult = NonNullable<
  Awaited<ReturnType<typeof postPointProductConfirm>>
>;
export type PostPointProductCreateResult = NonNullable<
  Awaited<ReturnType<typeof postPointProductCreate>>
>;
