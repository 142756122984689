"use client";
import { useCallback, useMemo } from "react";

import { getCustomer } from "@/generated/axios-functions/payseAPI";
import {
  convertCustomerToLocalStorageCustomer,
  convertLocalStorageCustomerToCustomer,
} from "@/models/customer/converters";
import { CustomerModel } from "@/models/customer/type";

import { LocalStagePath, NuxtStoreCustomer } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";
import { useAuth } from "../components/domains/auth";

const storeKey: LocalStagePath = `vuex.customer.customer`;

const CustomerPromiseStore = new Map<string, Promise<void>>();

/**
 * ローカルストレージに保存されているユーザー情報を取得する
 * ログインしているかつ、ユーザー情報がない場合は、Suspenseする
 * クライアントサイドでのみ利用可能
 */
export function useClientCustomerCustomer() {
  const { isLoggedIn, accessToken } = useAuth();
  const [storageCustomer, setStorageCustomer] = useClientLocalStorage<
    NuxtStoreCustomer["customer"] | undefined
  >({ key: storeKey });

  const customer = useMemo(
    // ログインしていない場合は、nullを返す
    () => (isLoggedIn ? convertLocalStorageCustomerToCustomer(storageCustomer) : null),
    [isLoggedIn, storageCustomer]
  );

  // ログインしてるが、customerがない場合はSuspense
  if (isLoggedIn && !customer) {
    if (CustomerPromiseStore.has(accessToken)) {
      throw CustomerPromiseStore.get(accessToken)!;
    }
    const promise = new Promise<void>(async (resolve, reject) => {
      try {
        const { customer } = await getCustomer();
        if (!customer) {
          throw new Error("Customer not found");
        }
        setStorageCustomer(customer as NuxtStoreCustomer["customer"]);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
    CustomerPromiseStore.set(accessToken, promise);
    throw promise;
  }

  const setCustomer = useCallback(
    (newCustomer: CustomerModel | ((prev: CustomerModel | undefined) => CustomerModel)) => {
      setStorageCustomer((prev) => {
        const prevCustomer = convertLocalStorageCustomerToCustomer(prev);
        const newValue =
          typeof newCustomer === "function" ? newCustomer(prevCustomer) : newCustomer;
        return convertCustomerToLocalStorageCustomer(newValue);
      });
    },
    [setStorageCustomer]
  );

  const clearCustomer = useCallback(() => {
    setStorageCustomer(undefined);
  }, [setStorageCustomer]);

  return {
    customer: customer ?? null,
    setCustomer,
    clearCustomer,
  };
}
