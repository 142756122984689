"use client";
import { useMemo } from "react";

import { useCookies } from "react-cookie";
import { z } from "zod";

const isServer = typeof window === "undefined";

export const CookieSchema = z.object({
  payse_token_v2: z.string().optional(),
});

export type CookieSchemaValues = z.infer<typeof CookieSchema>;

// クッキーをオブジェクトに変換する関数
const parseCookies = (cookieArray: { name: string; value: string }[]) => {
  return cookieArray.reduce(
    (acc, { name, value }) => {
      acc[name] = value;
      return acc;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as { [x: string]: any }
  );
};

/**
 * クライアントサイド、サーバーサイド(use client)の両方でクッキーを取得するカスタムフック
 * @warning
 * - サーバーコンポーネントでは利用できません。`next/headers`の`cookies`をご利用ください。
 * - サーバーサイドでのみ利用可能なクッキーを取得する場合は、`next/headers`の`cookies`をご利用ください。
 * @returns
 */
export function useCookiesAnywhere(dependencies?: string[]) {
  const [clientCookies, setCookie, removeCookie] = useCookies(dependencies);

  const cookies = useMemo(() => {
    if (isServer) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const serverCookies = parseCookies(require("next/headers").cookies().getAll());
      const res = CookieSchema.safeParse(serverCookies);
      if (res.success) {
        return res.data;
      }
    } else {
      const res = CookieSchema.safeParse(clientCookies);
      if (res.success) {
        return res.data;
      }
    }
    return CookieSchema.parse({});
  }, [clientCookies]);

  return [cookies, setCookie, removeCookie] as const;
}
