import RootAxios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import urlJoin from "url-join";

import { getAuthInfo } from "@/components/domains/auth";
import { logResponse } from "@/configs/logging";

const baseURL =
  typeof window === "undefined"
    ? urlJoin(process.env.INTERNAL_BASE_URL ?? "", "/api/v2")
    : urlJoin(process.env.NEXT_PUBLIC_APP_URL ?? "", "/api/v2");

/**
 * サーバー側とブラウザ側でインスタンスを分ける
 * - サーバー側は毎回新しいインスタンスを生成する
 * - ブラウザ側は一度生成したインスタンスを使いまわす
 */
let browserAxiosInstance: AxiosInstance;
const getAxiosInstance = (): AxiosInstance => {
  if (typeof window === "undefined") {
    const instance = RootAxios.create({
      baseURL,
    });

    instance.interceptors.response.use(logResponse);
    return instance;
  }

  if (!browserAxiosInstance) {
    browserAxiosInstance = RootAxios.create({
      baseURL,
    });
  }

  return browserAxiosInstance;
};

type CustomAxiosInstanceAxiosRequestConfig = AxiosRequestConfig & {
  noAuth?: boolean; // Jwt-Authorizationが不要な場合にtrue
};

const emptyAuth = { accessToken: undefined, isLoggedIn: false };

/**
 * orval用
 */
export const customAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: CustomAxiosInstanceAxiosRequestConfig
): Promise<T> => {
  const { noAuth = false, ...restOptions } = options ?? {};
  const { accessToken, isLoggedIn } = noAuth ? emptyAuth : getAuthInfo();
  const source = RootAxios.CancelToken.source();
  const headers = {
    "Content-Type": "application/json",
    ...config.headers,
    ...restOptions?.headers,
    ...(isLoggedIn && { "Jwt-Authorization": `Bearer ${accessToken}` }),
  };

  const AxiosInstance = getAxiosInstance();

  const promise = AxiosInstance({
    ...config,
    ...restOptions,
    headers,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-expect-error promiseがanyなので
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;

/**
 * 静的な関数内で利用可能なaxiosインスタンスを返す
 * @returns
 */
export const getAxios = (config?: AxiosRequestConfig) => {
  const { accessToken, isLoggedIn } = getAuthInfo();
  return RootAxios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      ...(isLoggedIn && { "Jwt-Authorization": `Bearer ${accessToken}` }),
    },
    ...config, // headerの上書きをするため後に置く
  });
};
